<template>
  <div class="app-container calendar-list-container">
    <basic-container>
      <template>
        <el-tabs v-model="switchStatus" @tab-click="switchTab">
          <el-tab-pane label="信息管理" name="userManager" />
          <el-tab-pane label="密码管理" name="passwordManager" />
        </el-tabs>
      </template>
      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-form
              v-if="switchStatus === 'userManager'"
              ref="ruleForm2"
              :model="ruleForm2"
              :rules="rules2"
              label-width="100px"
              class="demo-ruleForm"
            >
              <el-form-item label="用户名：" prop="username">
                <el-input
                  v-model="ruleForm2.username"
                  type="text"
                  disabled
                  style="width: 50%"
                ></el-input>
              </el-form-item>
              <el-form-item label="显示名：" prop="displayName">
                <el-input
                  v-model="ruleForm2.displayName"
                  type="text"
                  auto-complete="new-password"
                  style="width: 50%"
                ></el-input>
              </el-form-item>
              <el-form-item label="邮箱：" prop="email">
                <el-input
                  v-model="ruleForm2.email"
                  type="text"
                  style="width: 50%"
                ></el-input>
              </el-form-item>
              <el-form-item label="手机号：" prop="phone">
                <el-input
                  v-model="ruleForm2.phone"
                  style="width: 50%"
                ></el-input>
              </el-form-item>
              <el-form-item label="企业微信号：" prop="wechatId">
                <el-input
                  v-model="ruleForm2.wechatId"
                  style="width: 50%"
                ></el-input>
                <el-button type="text" class="sweep-btn" @click="sweepCode"
                  >扫码添加</el-button
                >
              </el-form-item>
              <el-form-item>
                <el-button
                  v-if="permissionList.submitBtn1"
                  type="primary"
                  @click="submitForm('ruleForm2')"
                  >提交</el-button
                >
                <el-button @click="resetFormInfo()">重置</el-button>
              </el-form-item>
            </el-form>
            <el-form
              v-if="switchStatus === 'passwordManager'"
              ref="ruleForm2"
              :model="ruleForm2"
              :rules="rules2"
              label-width="100px"
              class="demo-ruleForm"
            >
              <el-form-item></el-form-item>
              <el-form-item label="新密码：" prop="password">
                <el-input
                  v-model="ruleForm2.password"
                  type="password"
                  auto-complete="off"
                  show-password
                  style="width: 50%; margin-right: 16px"
                ></el-input>
                <span
                  ><i class="el-icon-warning"></i
                  >数字大小写字母特殊字符每种至少包含一个，并且8~20位长度</span
                >
              </el-form-item>
              <el-form-item label="确认密码：" prop="newPassword">
                <el-input
                  v-model="ruleForm2.newPassword"
                  type="password"
                  auto-complete="off"
                  show-password
                  style="width: 50%"
                ></el-input>
              </el-form-item>

              <el-form-item>
                <el-button
                  v-if="permissionList.submitBtn2"
                  type="primary"
                  @click="submitForm('ruleForm2', 'password')"
                  >提交</el-button
                >
                <el-button @click="resetFormPassword()">重置</el-button>
              </el-form-item>
            </el-form>
          </div>
          <el-dialog
            title="扫码添加"
            :visible="sweepVisible"
            @close="sweepClose"
          >
            <div id="wx_reg" class="wx_reg"></div>
          </el-dialog>
        </el-col>
      </el-row>
    </basic-container>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import store from '@/store'
import request from '@/router/axios'
import {
  validateTrim,
  validatePhone,
  validateEmail,
  validatePassword,
} from '@/util/util.js'
import '@/util/wwLogin-1.0.0'
import { getWechatUerInfo, getWeChatSettings } from '@/api/admin/user'
export default {
  data() {
    var validatePw1 = (rule, value, callback) => {
      if (this.ruleForm2.newPassword !== '') {
        this.$refs.ruleForm2.validateField('newPassword')
      }
      callback()
    }
    const validatePw2 = (rule, value, callback) => {
      if (value !== this.ruleForm2.password) {
        callback(new Error('两次输入密码不一致'))
      } else {
        callback()
      }
    }
    return {
      switchStatus: '',
      Url: '',
      show: false,
      headers: {
        Authorization: 'Bearer ' + store.getters.access_token,
      },
      ruleForm2: {
        username: '',
        displayName: '',
        password: '',
        newPassword: '',
        phone: '',
        email: '',
        wechatId: '',
      },
      rules2: {
        displayName: [
          {
            max: 20,
            message: '显示名最大20个字符',
            trigger: 'change',
          },
          {
            validator: validateTrim,
            trigger: 'change',
          },
        ],
        email: [
          {
            validator: validateTrim,
            trigger: 'change',
          },
          {
            validator: validateEmail,
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
          },
          {
            validator: validatePassword,
            trigger: 'blur',
          },
          {
            validator: validatePw1,
            trigger: 'blur',
          },
        ],
        newPassword: [
          {
            required: true,
            message: '请输入确认密码',
          },
          {
            validator: validatePassword,
            trigger: 'blur',
          },
          {
            validator: validatePw2,
            trigger: 'change',
          },
        ],
        phone: [
          {
            validator: validateTrim,
            trigger: 'change',
          },
          {
            validator: validatePhone,
            trigger: 'change',
          },
        ],
      },
      sweepVisible: false,
      timer: -1,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
    ...mapGetters(['permissions']),
    permissionList() {
      return {
        submitBtn1: this.vaildData(this.permissions.infomanage_edit, false),
        submitBtn2: this.vaildData(this.permissions.pwdmanage_edit, false),
      }
    },
  },
  created() {
    this.ruleForm2.username = this.userInfo.username
    this.ruleForm2.displayName = decodeURIComponent(this.userInfo.displayName)
    this.ruleForm2.email = this.userInfo.email
    this.ruleForm2.phone = this.userInfo.phone
    this.ruleForm2.sourceFlag = this.userInfo.sourceFlag
    this.ruleForm2.wechatId = this.userInfo.wechatId
    this.switchStatus = 'userManager'
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    switchTab(tab, event) {
      if (this.$refs.ruleForm2) {
        this.$nextTick(() => {
          this.$refs.ruleForm2.clearValidate()
        })
      }
      this.switchStatus = tab.name
    },
    submitForm(formName, password) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          request({
            url: password
              ? '/api/onlyone/authcenter/user/editPwd '
              : '/api/onlyone/authcenter/user/edit',
            method: 'put',
            data: this.ruleForm2,
          })
            .then(response => {
              if (response.data.data) {
                this.$notify({
                  title: '成功',
                  message: '修改成功',
                  type: 'success',
                  duration: 2000,
                })
                // 修改密码之后强制重新登录
                if (this.switchStatus === 'passwordManager') {
                  this.$store.dispatch('LogOut').then(() => {
                    location.reload() // 为了重新实例化vue-router对象 避免bug
                  })
                } else {
                  // 修改用户信息重新获取用户信息
                  this.$store.dispatch('GetUserInfo')
                }
              } else {
                this.$notify({
                  title: '失败',
                  message: response.data.message,
                  type: 'error',
                  duration: 2000,
                })
              }
            })
            .catch(() => {
              this.$notify({
                title: '失败',
                message: '修改失败',
                type: 'error',
                duration: 2000,
              })
            })
        } else {
          return false
        }
      })
    },
    resetFormInfo() {
      this.ruleForm2.displayName = ''
      this.ruleForm2.phone = ''
      this.ruleForm2.email = ''
      this.ruleForm2.wechatId = ''
      this.$nextTick(() => {
        this.$refs.ruleForm2.clearValidate()
      })
    },
    resetFormPassword() {
      this.ruleForm2.password = ''
      this.ruleForm2.newPassword = ''
      this.$nextTick(() => {
        this.$refs.ruleForm2.clearValidate()
      })
    },
    sweepCode() {
      this.sweepVisible = true
      getWeChatSettings().then(res => {
        if (res.data.code === 0) {
          const { corpId, agentId, domainName } = res.data.data
          window.WwLogin({
            id: 'wx_reg',
            appid: corpId,
            agentid: agentId,
            redirect_uri: encodeURIComponent(
              `${window.location.protocol}//${domainName}/#/console/info/index`,
            ),
            state: '',
            href: '',
          })
          const that = this
          that.timer = setInterval(function () {
            const codeMessage = JSON.parse(
              localStorage.getItem('OO-codeMessage'),
            )
            if (codeMessage && codeMessage.code) {
              that.getWechatUerInfo(codeMessage)
              that.sweepClose()
              clearInterval(that.timer)
            }
          }, 1000)
        }
      })
    },
    getWechatUerInfo(codeMessage) {
      localStorage.removeItem('OO-codeMessage')
      getWechatUerInfo(codeMessage).then(res => {
        if (res.data.code === 0) {
          this.ruleForm2.wechatId = res.data.message
        }
      })
    },
    sweepClose() {
      this.sweepVisible = false
    },
  },
}
</script>
<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px !important;
  color: #8c939d !important;
  width: 178px !important;
  height: 178px !important;
  line-height: 178px !important;
  text-align: center !important;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.sweep-btn {
  margin-left: 16px;
}
.wx_reg {
  text-align: center;
}
</style>
