<template>
  <div class="base-datepicker-container">
    <el-popover
      v-model="isShowRefresh"
      placement="bottom"
      width="500"
      trigger="click"
    >
      <div slot="reference" class="refresh">
        <span v-if="refreshInfo.label != '自动刷新'">
          <em
            :class="{
              'el-icon-video-pause': refreshInfo.start,
              'el-icon-video-play': !refreshInfo.start,
            }"
            :title="refreshInfo.start ? '停止自动刷新' : '启动自动刷新'"
            @click.stop="startOrStop"
          ></em>
          {{ refreshInfo.label }}
        </span>
        <span v-else>
          <em
            :class="['el-icon-refresh', isRefresh ? 'refresh-animate' : '']"
            title="刷新"
            @click.stop="refresh"
          ></em>
          {{ refreshInfo.label }}
        </span>
      </div>
      <div class="refresh-panel">
        <span class="close-refresh" @click="autoRefresh()">关闭</span>
        <div
          v-for="auto in autoRefreshList"
          :key="auto.label"
          @click="autoRefresh(auto)"
        >
          {{ auto.label }}
        </div>
      </div>
    </el-popover>
    <el-popover
      v-model="isShowTimePanel"
      placement="bottom"
      width="675"
      trigger="click"
    >
      <div slot="reference" class="datetime-display">
        <em class="el-icon-arrow-left"></em>
        <span>{{ dateTimeInfo.label }}</span>
        <em class="el-icon-arrow-right"></em>
      </div>
      <div class="time-panel">
        <em class="up-arrow"></em>
        <el-tabs value="quick">
          <el-tab-pane label="快速选择" name="quick">
            <div class="quick-container">
              <div
                v-for="quick in quickList"
                :key="quick.label"
                class="quick"
                @click="quickSelect(quick)"
              >
                {{ quick.label }}
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="自定义" name="absolute">
            <date-range ref="dateRange" @pick="absoluteSelect" />
          </el-tab-pane>
          <el-tab-pane label="最近选择" name="recent">
            <div class="recent-container">
              <div
                v-for="(recent, index) in recentList"
                :key="index"
                @click="recentSelect(recent)"
              >
                {{ recent.label }}
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-popover>
  </div>
</template>

<script>
import { autoRefreshList, quickList } from '../datepicker'
import dateRange from 'element-ui/packages/date-picker/src/panel/date-range.vue'
import moment from 'moment'
export default {
  components: {
    dateRange,
  },
  props: {
    initTime: {
      type: Object,
      default: () => {
        const endTime = new Date().getTime()
        const startTime = endTime - 5 * 60 * 1000
        return {
          label: '过去5分钟',
          startTime,
          endTime,
        }
      },
    },
  },
  data() {
    return {
      autoRefreshList: autoRefreshList,
      quickList: quickList,
      isShowRefresh: false,
      isShowTimePanel: false,
      refreshInfo: { label: '自动刷新', value: 0, start: false },
      dateTimeInfo: this.initTime,
      refreshTnterval: '',
      recentList: [],
      isRefresh: false,
    }
  },
  created() {
    this.$emit('blurEvent', this.dateTimeInfo)
  },
  mounted() {
    this.$refs.dateRange.showTime = true
    this.$refs.dateRange.visible = true
    // this.$refs.dateRange.disabledDate = time => {
    //   return time.getTime() > Date.now() - 8.64e6
    // }
  },
  methods: {
    // 刷新
    refresh() {
      this.isRefresh = true
      this.compareAndRecent(this.dateTimeInfo, 'refresh')
      setTimeout(() => {
        this.isRefresh = false
      }, 1000)
    },
    // 自动刷新频率
    autoRefresh(autoValue) {
      this.isShowRefresh = false
      clearInterval(this.refreshTnterval)
      if (!autoValue) {
        this.refreshInfo = { label: '自动刷新', value: 0, start: true }
      } else {
        this.refreshInfo = {
          label: autoValue.label,
          value: autoValue.value,
          start: true,
        }
        this.refreshTnterval = setInterval(() => {
          if (this.dateTimeInfo.label.indexOf('To') === -1) {
            this.compareAndRecent(this.dateTimeInfo)
          } else {
            this.$emit('blurEvent', this.dateTimeInfo)
          }
        }, this.refreshInfo.value)
      }
    },
    // 启动或停止自动刷新
    startOrStop() {
      if (this.refreshInfo.start) {
        clearInterval(this.refreshTnterval)
      } else {
        this.refreshTnterval = setInterval(() => {
          if (this.dateTimeInfo.label.indexOf('To') === -1) {
            this.compareAndRecent(this.dateTimeInfo)
          } else {
            this.$emit('blurEvent', this.dateTimeInfo)
          }
        }, this.refreshInfo.value)
      }
      this.refreshInfo.start = !this.refreshInfo.start
    },
    // 快捷选择
    quickSelect(quick) {
      this.dateTimeInfo.label = quick.label
      this.isShowTimePanel = false
      const dateTime = quick.value()
      this.dateTimeInfo.startTime = dateTime.startTime.getTime()
      this.dateTimeInfo.endTime = dateTime.endTime.getTime()
      this.$emit('blurEvent', this.dateTimeInfo)
      this.judgeUnique(this.dateTimeInfo)
    },
    // 自定义选择
    absoluteSelect(dateTime) {
      if (dateTime && dateTime.length > 0) {
        const startTime = new Date(dateTime[0]).getTime()
        const endTime = new Date(dateTime[1]).getTime()
        this.dateTimeInfo.label = `${moment(startTime).format(
          'YYYY-MM-DD HH:mm:ss',
        )} To ${moment(endTime).format('YYYY-MM-DD HH:mm:ss')}`
        this.dateTimeInfo.startTime = startTime
        this.dateTimeInfo.endTime = endTime
        this.isShowTimePanel = false
        this.$emit('blurEvent', this.dateTimeInfo)
        this.judgeUnique(this.dateTimeInfo)
      }
    },
    // 最近选择
    recentSelect(recent) {
      if (recent.label.indexOf('To') === -1) {
        this.compareAndRecent(recent)
        this.isShowTimePanel = false
      } else {
        this.dateTimeInfo = JSON.parse(JSON.stringify(recent))
        this.isShowTimePanel = false
        this.$emit('blurEvent', this.dateTimeInfo)
      }
    },
    // 快捷选择传输实时数据
    compareAndRecent(curquick, type) {
      if (curquick.label.indexOf('To') !== -1) {
        this.$emit('blurEvent', curquick, type)
      } else {
        this.quickList.forEach(quick => {
          if (quick.label === curquick.label) {
            this.dateTimeInfo.label = quick.label
            const dateTime = quick.value()
            this.dateTimeInfo.startTime = dateTime.startTime.getTime()
            this.dateTimeInfo.endTime = dateTime.endTime.getTime()
            this.$emit('blurEvent', this.dateTimeInfo, type)
          }
        })
      }
    },
    // 判断最近选择是否已经有当前时间
    judgeUnique(curDatetime) {
      const labelList = this.recentList.map(recent => {
        return recent.label
      })
      if (!labelList.includes(curDatetime.label)) {
        this.recentList.unshift(JSON.parse(JSON.stringify(curDatetime)))
      }
    },
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.base-datepicker-container {
  color: #606266;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 8px;
  cursor: pointer;
  position: relative;
  height: 32px;
  border-radius: 3px;
  .refresh {
    margin-right: 8px;
    > span {
      display: flex;
      align-items: center;
      white-space: nowrap;
      em {
        font-size: 18px;
        margin-right: 6px;
      }
    }
  }
  .refresh-animate {
    animation: rotate 0.5s linear infinite;
  }
  @keyframes rotate {
    from {
      transform: rotateZ(0deg);
    }
    to {
      transform: rotateZ(360deg);
    }
  }
  .datetime-display {
    white-space: nowrap;
    .el-icon-arrow-left {
      margin-right: 8px;
    }
    .el-icon-arrow-right {
      margin-left: 8px;
    }
  }
}

.refresh-panel {
  text-align: left;
  padding: 4px;
  border-radius: 3px;
  cursor: pointer;
  .close-refresh {
    position: absolute;
    top: 20px;
    right: 30px;
  }
  > div {
    display: inline-block;
    width: 33.3%;
    margin: 4px 0;
  }
}
.time-panel {
  text-align: center;
  cursor: pointer;
  border-radius: 3px;
  padding: 4px;
  ::v-deep .el-tabs__header {
    margin: 0 0 8px;
  }
  ::v-deep .el-picker-panel {
    border: none;
    box-shadow: none;
  }
  .quick-container {
    white-space: pre-wrap;
    text-align: left;
    .quick {
      display: inline-block;
      width: 33.3%;
      margin: 4px 0;
    }
  }
  .recent-container {
    max-height: 200px;
    min-height: 100px;
    overflow-y: auto;
    text-align: left;
    > div {
      margin-bottom: 8px;
    }
  }
}
</style>
