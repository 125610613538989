const autoRefreshList = [
  {
    label: '5 seconds',
    value: 5 * 1000,
  },
  {
    label: '1 minute',
    value: 1 * 60 * 1000,
  },
  {
    label: '1 hour',
    value: 1 * 60 * 60 * 1000,
  },
  {
    label: '10 seconds',
    value: 10 * 1000,
  },
  {
    label: '5 minutes',
    value: 5 * 60 * 1000,
  },
  {
    label: '2 hours',
    value: 2 * 60 * 60 * 1000,
  },
  {
    label: '30 seconds',
    value: 30 * 1000,
  },
  {
    label: '15 minutes',
    value: 15 * 60 * 1000,
  },
  {
    label: '12 hours',
    value: 12 * 60 * 60 * 1000,
  },
  {
    label: '45 seconds',
    value: 45 * 1000,
  },
  {
    label: '30 minutes',
    value: 30 * 60 * 1000,
  },
  {
    label: '1 day',
    value: 24 * 60 * 60 * 1000,
  },
]
const quickList = [
  {
    label: '过去5分钟',
    value() {
      const endTime = new Date()
      const startTime = new Date()
      startTime.setTime(startTime.getTime() - 5 * 60 * 1000)
      return { startTime, endTime }
    },
  },
  {
    label: '过去15分钟',
    value(picker) {
      const endTime = new Date()
      const startTime = new Date()
      startTime.setTime(startTime.getTime() - 15 * 60 * 1000)
      return { startTime, endTime }
    },
  },
  {
    label: '过去30分钟',
    value(picker) {
      const endTime = new Date()
      const startTime = new Date()
      startTime.setTime(startTime.getTime() - 30 * 60 * 1000)
      return { startTime, endTime }
    },
  },
  {
    label: '过去1小时',
    value(picker) {
      const endTime = new Date()
      const startTime = new Date()
      startTime.setTime(startTime.getTime() - 60 * 60 * 1000)
      return { startTime, endTime }
    },
  },
  {
    label: '过去3小时',
    value(picker) {
      const endTime = new Date()
      const startTime = new Date()
      startTime.setTime(startTime.getTime() - 3 * 60 * 60 * 1000)
      return { startTime, endTime }
    },
  },
  {
    label: '过去6小时',
    value(picker) {
      const endTime = new Date()
      const startTime = new Date()
      startTime.setTime(startTime.getTime() - 6 * 60 * 60 * 1000)
      return { startTime, endTime }
    },
  },
  {
    label: '过去12小时',
    value(picker) {
      const endTime = new Date()
      const startTime = new Date()
      startTime.setTime(startTime.getTime() - 12 * 60 * 60 * 1000)
      return { startTime, endTime }
    },
  },
  {
    label: '过去24小时',
    value(picker) {
      const endTime = new Date()
      const startTime = new Date()
      startTime.setTime(startTime.getTime() - 24 * 60 * 60 * 1000)
      return { startTime, endTime }
    },
  },
  {
    label: '今天',
    value(picker) {
      const endTime = new Date()
      const startTime = new Date()
      startTime.setHours(0)
      startTime.setMinutes(0)
      startTime.setSeconds(0)
      endTime.setHours(23)
      endTime.setMinutes(59)
      endTime.setSeconds(59)
      return { startTime, endTime }
    },
  },
  {
    label: '过去7天',
    value(picker) {
      const endTime = new Date()
      const startTime = new Date()
      startTime.setTime(startTime.getTime() - 3600 * 1000 * 24 * 6)
      return { startTime, endTime }
    },
  },
  {
    label: '过去30天',
    value(picker) {
      const endTime = new Date()
      const startTime = new Date()
      startTime.setTime(startTime.getTime() - 3600 * 1000 * 24 * 29)
      return { startTime, endTime }
    },
  },
]
export { autoRefreshList, quickList }
