import { validateUrl } from '@/util/util'

export const ldapForm = {
  id: 0,
  serviceUrl: '',
  bindDn: '',
  bindPwd: '',
  searchOu: '',
  filter: '',
  attrMap: '',
  openFlag: '',
  type: 'ldap',
}
export const selfDefinedForm = {
  id: 0,
  serviceUrl: '',
  secretKey: '',
  requestTimeout: '1500',
  openFlag: '',
  type: 'custom',
}
export const testLoginForm = {
  username: '',
  password: '',
  type: 'ldap',
}
export const rules = {
  serviceUrl: [
    {
      required: true,
      message: '请输入LDAP地址',
      trigger: 'blur',
    },
    {
      validator: validateUrl,
      trigger: 'blur',
    },
  ],
  bindDn: [
    {
      required: true,
      message: '请输入绑定DN',
      trigger: 'blur',
    },
  ],
  searchOu: [
    {
      required: true,
      message: '请输入用户Ou',
      trigger: 'blur',
    },
  ],
  bindPwd: [
    {
      required: true,
      message: '请输入密码',
      trigger: 'blur',
    },
  ],
  password: [
    {
      required: true,
      message: '请输入密码',
      trigger: 'blur',
    },
  ],
  filter: [
    {
      required: true,
      message: '请输入用户过滤器',
      trigger: 'blur',
    },
  ],
  attrMap: [
    {
      required: true,
      message: '请输入LDAP属性映射',
      trigger: 'blur',
    },
  ],
  username: [
    {
      required: true,
      message: '请输入用户名',
      trigger: 'blur',
    },
  ],
}
export const rulesCustom = {
  serviceUrl: [
    {
      required: true,
      message: '请输入登录服务URL',
      trigger: 'blur',
    },
    {
      validator: validateUrl,
      trigger: 'blur',
    },
  ],
  secretKey: [],
  requestTimeout: [
    {
      required: true,
      message: '请输入默认超时时间(ms)',
      trigger: 'blur',
    },
  ],
}
