import request from '@/router/axios'
import qs from 'qs'
// 详情
export function getAuditLog(data) {
  console.log('-')
  return request({
    url: `/api/onlyone/admin/v1/rest/audit/getAuditLog?${qs.stringify(data)}`,
    method: 'get',
  })
}
// 下载
export function exportExcel(data) {
  return request({
    url: '/api/onlyone/admin/v1/rest/audit/exportAuditLog',
    method: 'post',
    data: data,
    responseType: 'blob',
    // headers: {
    //   'Content-Type':
    //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset:utf-8',
    // },
  })
}
// 用户列表
export function listAuditLogUsers() {
  return request({
    url: '/api/onlyone/admin/v1/rest/audit/users',
    method: 'get',
  })
}
// app列表
export function listAuditLogApps() {
  return request({
    url: '/api/onlyone/admin/v1/rest/audit/apps',
    method: 'get',
  })
}
// 列表
export function listAuditLogs(data) {
  return request({
    url: `/api/onlyone/admin/v1/rest/audit/listAuditLog`,
    method: 'post',
    data,
  })
}
