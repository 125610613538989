<template>
  <basic-container>
    <div class="content-box">
      <div class="content-user-audit content-box-inner">
        <div class="content-header">
          <div class="select-list">
            <div class="operate-user">
              <label>选择应用:</label>
              <el-select
                v-model="form.appName"
                size="small"
                placeholder="请选择应用"
                clearable
                @change="getChange"
              >
                <el-option
                  v-for="(item, index) in appList"
                  :key="index"
                  :value="item"
                  :label="item"
                ></el-option>
              </el-select>
            </div>
            <div class="operate-user">
              <label>操作用户:</label>
              <el-select
                v-model="form.operationUser"
                size="small"
                placeholder="请选择用户"
                clearable
                @change="getChange"
              >
                <el-option
                  v-for="(item, index) in userList"
                  :key="index"
                  :value="item"
                  :label="item"
                ></el-option>
              </el-select>
            </div>
            <div class="operate-type">
              <label>操作类型:</label>
              <el-select
                v-model="form.operationType"
                size="small"
                placeholder="请选择类型"
                clearable
                @change="getChange"
              >
                <el-option value="新增" label="新增"></el-option>
                <el-option value="修改" label="修改"></el-option>
                <el-option value="删除" label="删除"></el-option>
                <el-option value="生效" label="生效"></el-option>
                <el-option value="失效" label="失效"></el-option>
                <el-option value="下载" label="下载"></el-option>
                <el-option value="登录" label="登录"></el-option>
                <el-option value="查询" label="查询"></el-option>
              </el-select>
            </div>
            <div class="operate-obj">
              <el-input
                v-model="form.keyword"
                placeholder="自定义搜索"
                size="small"
                @keyup.enter.native="getChange"
              >
                <em
                  slot="suffix"
                  class="el-input__icon el-icon-search"
                  @click="getChange"
                ></em>
              </el-input>
            </div>
            <div class="operate">
              <el-button
                size="small"
                :disabled="disExportExcel"
                @click="exportExcelBtn"
                >{{ disExportExcel ? '下载中' : '下载' }}
              </el-button>
              <date-picker ref="timePicker" @blurEvent="getTime"></date-picker>
            </div>
          </div>
        </div>
        <el-divider></el-divider>
        <div
          v-loading="loading"
          class="content-user-main"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgb(255,255,255)"
          element-loading-text="数据加载中..."
        >
          <el-table
            :data="tableData"
            size="medium"
            border
            style="width: 100%"
            @sort-change="sortChange"
          >
            <el-table-column
              prop="appName"
              label="所属应用"
              sortable="custom"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="operationUser"
              label="操作用户"
              sortable="custom"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.operationUser || '--' }}
              </template>
            </el-table-column>
            <el-table-column
              prop="clientIp"
              label="操作IP"
              sortable="custom"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.clientIp || '--' }}
              </template>
            </el-table-column>
            <el-table-column
              prop="operationType"
              label="操作类型"
              sortable="custom"
              width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="operationDescription"
              label="操作对象"
              show-overflow-tooltip
              sortable="custom"
            >
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="操作时间"
              width="240"
              align="center"
              sortable="custom"
            >
            </el-table-column>
            <el-table-column label="" width="120" align="center">
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  :underline="false"
                  @click="lookUp(scope.row, scope.$index)"
                  >查看</el-link
                >
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            style="text-align: right"
            :current-page="pageIndex"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>

      <el-dialog
        title="操作详情"
        :visible.sync="dialogVisible"
        class="height-fixed big"
      >
        <div class="dialog-content">
          <div class="show-detail" @click="getaround()">
            <div class="left-show">
              <div class="system">业务系统: {{ map.bizName || ' --' }}</div>
              <div class="system">操作类型: {{ map.operationType }}</div>
            </div>
            <div class="right-show">
              <div class="system">
                操作用户: {{ map.operationUser || '--' }}
              </div>
              <div class="system">操作对象: {{ map.operationDescription }}</div>
            </div>
          </div>

          <div
            v-if="
              map.operationType == '修改' ||
              map.operationType == '生效' ||
              map.operationType == '失效'
                ? true
                : false
            "
            class="operate-detail"
          >
            <div class="title">{{ map.operationType }}详情</div>
            <div v-for="(item, index) in changeList" :key="index">
              {{ item.title }}
              <template v-for="(column, idx) in item.data">
                <div :key="idx" class="show-json">
                  <div class="change-before">
                    <!-- <div class="title-name">变更前</div> -->
                    <div>
                      <pre v-html="column.beforeChange"></pre>
                    </div>
                  </div>
                  <div class="change-after">
                    <!-- <div class="title-name">变更后</div> -->
                    <div id="afterChange">
                      <pre v-html="column.afterChange"></pre>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div
            v-if="
              map.operationType == '新增' || map.operationType == '删除'
                ? true
                : false
            "
            class="add-delete"
          >
            <div class="change-after">
              <div class="title-name">{{ map.operationType }}详情</div>
              <div v-for="(item, index) in changeList" :key="index">
                {{ item.title }}
                <template v-for="(column, idx) in item.data">
                  <div :key="idx" class="show-json">
                    <div class="change-before">
                      <!-- <div class="title-name">变更前</div> -->
                      <div>
                        <pre
                          v-if="map.operationType == '新增'"
                          v-html="column.afterChange"
                        ></pre>
                        <pre v-else v-html="column.beforeChange"></pre>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogVisible = false"
            >关闭</el-button
          >
        </span>
      </el-dialog>
    </div></basic-container
  >
</template>

<script>
import {
  getAuditLog,
  exportExcel,
  listAuditLogUsers,
  listAuditLogApps,
  listAuditLogs,
} from '@/api/admin/audit'

import datePicker from './compontent/base-datepicker.vue'
export default {
  components: {
    datePicker,
  },
  data() {
    return {
      appList: [], // applist
      searchContent: '',
      timeChoose: '',
      tableData: [],
      detail: [],
      total: 0,
      dialogVisible: false,
      loading: false,
      pageIndex: 1,
      pageSize: 10,
      sortField: 'id',
      sortType: 'desc',
      startTimeStamp: '',
      endTimeStamp: '',
      map: {},
      userList: [],
      changeList: [],
      beforeChange: '',
      afterChange: '',
      statusShow: 2, // 1为表格展示, 2为json展示
      addDelete: '',
      timePickerTime: {},
      disExportExcel: false,
      form: { appName: '', operationUser: '', operationType: '', keyword: '' },
    }
  },
  created() {
    // this.subscribe = this.$store.subscribe(asyncdata => {})
  },
  destroyed() {
    // if (this.subscribe) {
    //   this.subscribe()
    // }
  },
  methods: {
    tipMessage(title = '', type = '', message = '') {
      this.$notify({
        title: title,
        type: type,
        message: message,
      })
    },
    // 分页
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageIndex = val
      this.getList()
    },
    // 时间
    getTime(data) {
      this.timePickerTime = data
      this.startTimeStamp = data.startTime
      this.endTimeStamp = data.endTime
      this.pageIndex = 1
      this.getList()
      this.getUerList()
      this.getAppName()
    },
    getChange(val) {
      this.pageIndex = 1
      this.refreshTime()
    },

    getaround() {
      let data = this.map.modifyState
      this.changeList = Object.keys(data).map(res => {
        let modifyData = {
          title: res,
          data: [],
        }
        modifyData.data = data[res].map(res => {
          let map = {}
          const mapbefore = {}
          const changeColorObj = []
          if (res) {
            let data =
              JSON.stringify(res.previousState) === '{}'
                ? res.currentState
                : res.previousState
            for (const i in data) {
              // json展示
              if (res.previousState[i] instanceof Object) {
                mapbefore[`${i}`] = JSON.stringify(res.previousState[i])
              } else {
                mapbefore[`${i}`] = res.previousState[i]
              }
              if (
                JSON.stringify(res.previousState[i]) ===
                JSON.stringify(res.currentState[i])
              ) {
                if (res.currentState[i] instanceof Object) {
                  map[`${i}`] = JSON.stringify(res.currentState[i])
                } else {
                  map[`${i}`] = res.currentState[i]
                }
              } else {
                changeColorObj.push(i)
                if (res.currentState[i] instanceof Object) {
                  map[`${i}`] = `<span>${JSON.stringify(
                    res.currentState[i],
                  )}</span>`
                } else {
                  map[`${i}`] = `<span>${res.currentState[i]}</span>`
                }
              }
            }

            if (JSON.stringify(res.currentState) === '{}') {
              map = {}
            } else {
              for (let i = 0; i < changeColorObj.length; i++) {
                const reg = new RegExp('' + changeColorObj[i] + '', 'g')
                map = JSON.parse(
                  JSON.stringify(map).replace(
                    reg,
                    `<span>${changeColorObj[i]}</span>`,
                  ),
                )
              }
            }
            return { afterChange: map, beforeChange: mapbefore }
          }
        })
        return modifyData
      })
    },
    // 查看详情
    lookUp(row, index) {
      this.map = {}
      this.changeList = []
      this.dialogVisible = true

      getAuditLog({ auditLogId: row.id })
        .then(res => {
          if (res.data.data) {
            this.map = res.data.data
            this.getaround()
          }
        })
        .catch(error => {
          this.tipMessage('错误', 'error', error)
        })
    },

    exportExcelBtn() {
      const data = {
        sortField: this.sortField,
        sortType: this.sortType,
        startTime: this.startTimeStamp,
        endTime: this.endTimeStamp,
        ...this.form,
      }
      this.disExportExcel = true
      exportExcel(data)
        .then(res => {
          this.disExportExcel = false
          const url = window.URL.createObjectURL(new Blob([res.data]))
          let filename = res.headers['content-disposition']
            .split(';')[1]
            .split('filename=')[1]
          filename = decodeURIComponent(filename)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = url
          a.setAttribute('download', filename)
          document.body.appendChild(a)
          a.click()
        })
        .catch(error => {
          this.disExportExcel = false
          this.tipMessage('错误', 'error', error)
        })
    },

    getUerList() {
      listAuditLogUsers()
        .then(res => {
          if (res.data.code === 0) {
            this.userList = res.data.data
          }
        })
        .catch(error => {
          this.tipMessage('错误', 'error', error)
        })
    },
    getAppName() {
      listAuditLogApps()
        .then(res => {
          if (res.data.code === 0) {
            this.appList = res.data.data
          }
        })
        .catch(error => {
          this.tipMessage('错误', 'error', error)
        })
    },
    // 查询刷新时间
    refreshTime() {
      this.$refs.timePicker.compareAndRecent(this.timePickerTime)
    },
    sortChange({ column, prop, order }) {
      if (order) {
        this.sortType = order === 'ascending' ? 'asce' : 'desc'
        this.sortField = prop
      } else {
        this.sortField = 'id'
        this.sortType = 'desc'
      }
      this.getList()
    },
    getList() {
      this.loading = true
      let params = {
        pageNo: this.pageIndex,
        pageSize: this.pageSize,
        sortField: this.sortField,
        sortType: this.sortType,
        startTime: this.startTimeStamp,
        endTime: this.endTimeStamp,
        ...this.form,
      }
      listAuditLogs(params)
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.records
            this.total = res.data.data.total
          }
          this.loading = false
        })
        .catch(error => {
          this.tipMessage('错误', 'error', error)
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.content-box {
  width: 100%;
  height: 100%;
  overflow: auto;
  border-radius: 8px;
  .content-user-audit {
    width: 100%;
    margin: 0;
    height: 100%;
    padding: 0;
    .content-header {
      .select-list {
        display: flex;
        > div {
          display: flex;
          align-items: center;
          margin-right: 16px;
          label {
            min-width: 80px;
            //   line-height: 32px;
            //   font-size: 14px;
          }
        }
        .btn-operate {
          display: flex;
          justify-content: flex-end;
        }
        .operate-user {
          flex: 4;
        }
        .operate-type {
          flex: 4;
        }
        .operate-obj {
          flex: 3;
        }
        .operate {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }

    .content-user-main {
      width: 100%;
      height: calc(100% - 64px);
    }
    .el-select {
      width: 100%;
    }
    .el-col {
      margin-bottom: 0;
    }
    .el-pagination {
      margin-top: 16px;
    }
  }
}
.dialog-content {
  .show-detail {
    display: flex;
    .left-show {
      margin-left: 20px;
      div {
        font-family: '微软雅黑 Regular', '微软雅黑';
        font-weight: 400;
        font-style: normal;
        // font-size: 12px;
        color: #868686;
        margin-bottom: 20px;
      }
    }
    .right-show {
      margin-left: 100px;
      div {
        font-family: '微软雅黑 Regular', '微软雅黑';
        font-weight: 400;
        font-style: normal;
        // font-size: 12px;
        color: #868686;
        margin-bottom: 20px;
      }
    }
  }
  .operate-detail {
    .title {
      margin: 10px 0;
    }
    .show-json {
      display: flex;
      width: 100%;
      height: 100%;
      .change-before {
        flex: 1;
        width: 50%;
        /* border-right: 1px solid darkgray; */
        .title-name {
          padding: 0 12px;
        }
      }
      .change-after {
        flex: 1;
        width: 50%;
        .title-name {
          padding: 0 12px;
        }
      }
    }
  }
  pre {
    // padding: 12px;
    overflow: auto;
    white-space: revert;
  }
}
::v-deep .change-after span {
  color: red !important;
}
</style>
