<template>
  <div class="login-container">
    <div class="login-form-container animated zoomIn">
      <el-form
        ref="login-form"
        :model="loginForm"
        label-width="100px"
        label-position="top"
      >
        <el-form-item label>
          <img
            :src="appIcon"
            alt="logo"
            class="fadeInRight animated logo icon"
          />
          <div class="Welcome">
            <span>一站式运维，发现价值</span>
            <span>{{ appName }}</span>
          </div>
        </el-form-item>
        <el-form-item label prop="username" class="user-password">
          <span>用户名:</span>
          <el-input
            v-model="loginForm.username"
            type="text"
            placeholder="请输入用户名"
          >
          </el-input>
        </el-form-item>
        <el-form-item label prop="password" class="user-password">
          <span>密码:</span>
          <el-input
            v-model="loginForm.password"
            type="password"
            placeholder="请输入密码"
            show-password
            @keyup.enter.native="handleLogin"
          >
          </el-input>
        </el-form-item>
        <el-form-item label prop="password" class="user-password">
          <span>验证码:</span>
          <div class="identify">
            <el-input
              style="margin-top: 5px"
              v-model="verificationCode"
              placeholder="请输入验证码"
              @keyup.enter.native="handleLogin"
            >
            </el-input
            ><span @click="refreshIdentifyCode">
              <s-identify
                :contentHeight="'32'"
                :identifyCode="identifyCode"
              ></s-identify
            ></span>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            :loading="loading"
            class="login-button"
            @click="handleLogin"
          >
            {{ loading ? '登录中...' : '登录' }}
          </el-button>
        </el-form-item>
      </el-form>
      <div class="login-version">
        <span>版本号: {{ version }}</span>
        <el-checkbox v-model="checked" @change="setUserInfo"
          >记住密码</el-checkbox
        >
      </div>
    </div>
    <img :src="Group5" alt="" class="login-form-img" />
  </div>
</template>
<script>
import SIdentify from './identify.vue'
import Group from '../../../public/img/login/Group.png'
import Group5 from '../../../public/img/login/Group5.png'
import { randomLenNum } from '@/util/util'
import { mapGetters } from 'vuex'
import store from '@/store/'
import { getWebConfigInfo, getVersion } from '@/api/login'
import Cookie from 'js-cookie'
export default {
  components: { SIdentify },
  data() {
    return {
      identifyCodes: '1234567890',
      identifyCode: '',
      verificationCode: '', // 验证码
      windowWidth: document.documentElement.clientWidth,
      checked: '',
      Group,
      Group5,
      loginForm: {
        username: '',
        password: '',
        code: '',
        redomStr: '',
        r_url: '',
      },
      code: {
        src: '/code',
        value: '',
        len: 4,
        type: 'image',
      },
      loading: false,
      backgroundRotate: false,
      appIcon: '',
      appName: '诺亚',
      version: '',
      defaultSaas: 'paas',
    }
  },
  computed: {
    ...mapGetters(['tagWel']),
  },
  watch: {
    windowWidth(val) {
      if (val > 1440) {
        document.querySelector('.login-form-img').style.right =
          'calc(50% + 80px)'
        document.querySelector('.login-form-container').style.left =
          'calc(50% + 80px)'
      } else {
        document.querySelector('.login-form-img').style.right =
          'calc(50% + 80px)'
        document.querySelector('.login-form-container').style.left =
          'calc(50% + 80px)'
      }
    },
  },
  created() {
    this.GetWebConfigInfo()
    this.GetVersion()
    this.refreshCode()
    this.getUserInfo()

    const content = localStorage.getItem('OO-paas-icon')
      ? JSON.parse(localStorage.getItem('OO-paas-icon')).content
      : ''
    this.appIcon = content.appIcon || this.appIcon
    this.appName = content.displayName || this.appName
  },
  mounted() {
    var that = this
    window.onresize = () => {
      return (() => {
        that.windowWidth = document.documentElement.clientWidth
      })()
    }
    if (this.windowWidth > 1440) {
      document.querySelector('.login-form-img').style.right = 'calc(50% + 80px)'
      document.querySelector('.login-form-container').style.left =
        'calc(50% + 80px)'
    } else {
      document.querySelector('.login-form-img').style.right = 'calc(50% + 80px)'
      document.querySelector('.login-form-container').style.left =
        'calc(50% + 80px)'
    }
    this.refreshIdentifyCode()
  },

  methods: {
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min)
    },
    refreshIdentifyCode() {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode +=
          this.identifyCodes[this.randomNum(0, this.identifyCodes.length)]
      }

      console.log(this.identifyCode)
    },

    // 获取记住的密码
    getUserInfo() {
      // 在页面加载时从cookie获取登录信息
      let account = Cookie.get('account')
      // 如果存在赋值给表单，并且将记住密码勾选
      if (account) {
        let password = Cookie.get('passWord')
        this.loginForm.username = account
        this.loginForm.password = password
        this.checked = true
      }
    },
    // 储存表单信息
    setUserInfo() {
      // 判断用户是否勾选记住密码，如果勾选，向cookie中储存登录信息，
      // 如果没有勾选，储存的信息为空
      if (this.checked) {
        Cookie.set('account', this.loginForm.username)
        // base64加密密码
        let passWord = this.loginForm.password
        Cookie.set('passWord', passWord)
      } else {
        Cookie.set('account', '')
        Cookie.set('passWord', '')
      }
    },
    /**
     * 接口获取Config信息
     */
    GetWebConfigInfo() {
      return new Promise((resolve, reject) => {
        getWebConfigInfo()
          .then(res => {
            this.backgroundRotate = res.data.data.backgroundRotate
            this.defaultSaas = res.data.data['defaultSaas'] || 'icube5'
            resolve('ok')
          })
          .catch(() => {
            reject()
          })
      })
    },
    /**
     * 查询版本信息
     */
    GetVersion() {
      return new Promise((resolve, reject) => {
        getVersion()
          .then(res => {
            this.version = res.data.data
            resolve('ok')
          })
          .catch(() => {
            reject()
          })
      })
    },
    refreshCode() {
      this.loginForm.code = ''
      this.loginForm.randomStr = randomLenNum(this.code.len, true)
      this.code.type === 'text'
        ? (this.code.value = randomLenNum(this.code.len))
        : (this.code.src = `${this.codeUrl}?randomStr=${this.loginForm.randomStr}`)
    },
    handleLogin() {
      // 必填校验
      if (this.loginForm.username === '' || this.loginForm.password === '') {
        this.$notify({
          title: '警告',
          message: '用户名或密码不能为空',
          type: 'warning',
          duration: 2000,
        })
        return
      }
      if (this.verificationCode !== this.identifyCode) {
        this.$notify({
          title: '警告',
          message: '验证码不正确',
          type: 'warning',
          duration: 2000,
        })
        this.refreshIdentifyCode()
        return
      }
      let isHasUrl = false
      if (window.location.href.indexOf('?') !== -1) {
        isHasUrl = true
        const fullUrl = window.location.href.split('?')[1]
        const url = fullUrl.split('=')[1]
        this.loginForm.r_url = decodeURIComponent(url)
      }
      this.$refs['login-form'].validate(valid => {
        if (valid) {
          this.$store
            .dispatch('LoginByUsername', this.loginForm)
            .then(() => {
              store.dispatch('GetUserInfo').then(() => {
                if (isHasUrl) {
                  window.location.href = decodeURIComponent(
                    this.loginForm.r_url,
                  )
                } else {
                  let redictUrl = ''
                  if (
                    this.defaultSaas === 'cmdb' ||
                    this.defaultSaas === 'smartdata' ||
                    this.defaultSaas === 'paas'
                  ) {
                    let host = window.location.host.split('.')
                    host.splice(0, 1)
                    redictUrl = `${window.location.protocol}//${
                      this.defaultSaas
                    }.${host.join('.')}`
                  } else {
                    redictUrl = `${location.origin}/app/${this.defaultSaas}/`
                  }
                  window.location.href = redictUrl
                }
              })
            })
            .catch(() => {
              this.refreshCode()
            })
        } else {
          this.loading = false
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.login-container {
  height: 100%;
  background-image: url('../../../public/img/login/mesh.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  .identify {
    display: flex;
    align-content: center;
  }
  .lizi {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .rotate-earth {
    position: absolute;
    z-index: 0;
    width: 100vw;
    bottom: -130vh;
    left: calc(50% - 100vw / 2);
    animation: earth-rotate 80s linear infinite;
  }
  .none-rotate-earth {
    position: absolute;
    z-index: 0;
    width: 100vw;
    bottom: -130vh;
    left: calc(50% - 100vw / 2);
  }
  .login-form-container {
    position: absolute;
    z-index: 2;
    width: 400px;
    background: #fff;
    border-radius: 8px;
    padding: 1% 3%;
    top: 22%;
    letter-spacing: 1px;
    .login-version {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      ::v-deep .el-checkbox__label {
        font-size: 12px;
        color: #2c3e50;
        font-weight: 400;
      }
    }
    .login-browser {
      display: flex;
      align-items: center;
      margin-left: 25%;
      color: #1a1a1a8a;
      font-size: 12px;
      margin-top: 9%;
    }

    .el-form-item:first-child {
      margin-bottom: 27.3px;
      ::v-deep .el-form-item__content {
        display: flex;
        align-items: center;
      }
    }
    .Welcome {
      display: inline-flex;
      flex-direction: column-reverse;
      span {
        margin-left: 19px;
      }
      :first-child {
        font-size: 12px;
      }
      :last-child {
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        font-family: 'Source Han Sans CN';
        font-style: normal;
      }
    }
    .user-password {
      padding: 8px 16px;
      border: 1px solid #1a1a1a0d;
      ::v-deep .el-input__inner {
        border: 0;
        font-size: 14px;
        padding: 0;
      }
      span {
        font-size: 12px;
        padding: 0 0 7px;
      }
    }
    .login-button {
      font-size: 14px;
    }
    ::v-deep .el-form-item__content {
      line-height: 16px;
    }
    .el-form-item:last-child {
      .el-button {
        width: 100%;
      }
    }
    ::v-deep .el-form {
      .el-input__prefix {
        left: 8px;
      }
      .el-button {
        letter-spacing: 1px;
      }
    }
    ::v-deep .el-checkbox__inner {
      border-radius: 6px;
    }
  }

  @keyframes earth-rotate {
    from {
      transform: rotateZ(0deg);
    }
    to {
      transform: rotateZ(360deg);
    }
  }
  .login-form-img {
    position: absolute;
    z-index: 2;
    width: 400px;
    border-radius: 8px;
    top: 22%;
    letter-spacing: 1px;
  }
}
</style>
