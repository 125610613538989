import request from '@/router/axios'

export function getListAuthMethodObj() {
  return request({
    url: '/api/onlyone/authcenter/v1/login/listAuthMethod',
    method: 'get',
  })
}
export function saveLdapObj(obj) {
  return request({
    url: '/api/onlyone/authcenter/v1/login/saveLdap',
    method: 'post',
    data: obj,
  })
}
export function saveCustomObj(obj) {
  return request({
    url: '/api/onlyone/authcenter/v1/login/saveCustom',
    method: 'post',
    data: obj,
  })
}
export function testLdapConnObj(obj) {
  return request({
    url: '/api/onlyone/authcenter/v1/login/testLdapConn',
    method: 'post',
    data: obj,
  })
}
export function testLdapLoginObj(obj) {
  const { username, password, type } = obj
  let params = new URLSearchParams()
  params.append('username', username)
  params.append('password', password)
  params.append('type', type)
  return request({
    url: '/api/onlyone/authcenter/v1/login/testLdapLogin',
    method: 'post',
    data: params,
  })
}
