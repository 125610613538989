<template>
  <div class="ldap-container">
    <el-tabs v-model="switchStatus" @tab-click="switchTab">
      <el-tab-pane label="LDAP" name="1">
        <el-form
          ref="ldapForm"
          :model="ldapForm"
          :rules="rules"
          label-width="240px"
        >
          <el-form-item label="LDAP地址：" prop="serviceUrl">
            <el-input v-model="ldapForm.serviceUrl"></el-input>
          </el-form-item>
          <el-form-item label="绑定DN：" prop="bindDn">
            <el-input v-model="ldapForm.bindDn"></el-input>
          </el-form-item>
          <el-form-item label="密码：" prop="bindPwd">
            <el-input
              v-model="ldapForm.bindPwd"
              type="password"
              auto-complete="new-password"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item label="用户OU：" prop="searchOu">
            <el-input v-model="ldapForm.searchOu"></el-input>
            <span>使用|分隔各OU</span>
          </el-form-item>
          <el-form-item label="用户过滤器：" prop="filter">
            <el-input v-model="ldapForm.filter"></el-input>
            <span>可能的选项是(cn或uid或sAMAccountName=%(user)s)</span>
          </el-form-item>
          <el-form-item label="LDAP属性映射：" prop="attrMap">
            <el-input
              v-model="ldapForm.attrMap"
              :rows="4"
              type="textarea"
            ></el-input>
            <span
              >用户属性映射代表怎样将LDAP中用户属性映射到PaaS用户上，username,real_name,mobile_phone,email,orgName是PaaS的属性</span
            >
          </el-form-item>
          <el-form-item label="启用LDAP认证：" prop="openFlag">
            <el-checkbox
              v-model="ldapForm.openFlag"
              style="margin-top: 6px"
            ></el-checkbox>
          </el-form-item>
          <el-form-item>
            <el-button @click="resetForm1('ruleForm')">重置</el-button>
            <el-button
              v-if="permissionList.conBtn"
              @click="testConn('ruleForm')"
              >测试连接</el-button
            >
            <el-button v-if="permissionList.loginBtn" @click="beforeTestLogin"
              >测试登录</el-button
            >
            <el-button
              v-if="permissionList.submitBtn"
              type="primary"
              @click="submitForm1('ruleForm')"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="自定义" name="2">
        <el-form
          ref="selfDefinedForm"
          :model="selfDefinedForm"
          :rules="rulesCustom"
          label-width="240px"
        >
          <el-form-item label="登录服务URL：" prop="serviceUrl">
            <el-input v-model="selfDefinedForm.serviceUrl"></el-input>
          </el-form-item>
          <el-form-item label="鉴权密钥：" prop="secretKey">
            <el-input v-model="selfDefinedForm.secretKey"></el-input>
          </el-form-item>
          <el-form-item label="默认超时时间(ms)：" prop="requestTimeout">
            <el-input-number
              v-model="selfDefinedForm.requestTimeout"
              :min="0"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="启用自定义认证：" prop="openFlag">
            <el-checkbox
              v-model="selfDefinedForm.openFlag"
              style="margin-top: 6px"
            ></el-checkbox>
          </el-form-item>
          <el-form-item>
            <el-button @click="resetForm2('ruleForm')">重置</el-button>
            <el-button
              v-if="permissionList.submitBtn"
              type="primary"
              @click="submitForm2('ruleForm')"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      title="测试LDAP用户登录"
    >
      <el-form
        ref="testLoginForm"
        :model="testLoginForm"
        :rules="rules"
        label-width="80px"
      >
        <el-form-item label="用户名：" prop="username">
          <el-input v-model="testLoginForm.username"></el-input>
        </el-form-item>
        <el-form-item label="密码：" prop="password">
          <el-input
            v-model="testLoginForm.password"
            type="password"
            auto-complete="new-password"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="testLogin('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import {
  ldapForm,
  selfDefinedForm,
  testLoginForm,
  rules,
  rulesCustom,
} from '@/const/crud/admin/login'
import {
  getListAuthMethodObj,
  saveLdapObj,
  saveCustomObj,
  testLdapConnObj,
  testLdapLoginObj,
} from '@/api/admin/login'
import { deepClone } from '@/util/util'
import * as CryptoJS from 'crypto-js'

export default {
  data() {
    return {
      switchStatus: '1',
      ldapForm: ldapForm,
      selfDefinedForm: selfDefinedForm,
      ldapFormCopy: {},
      selfDefinedFormCopy: {},
      testLoginForm: testLoginForm,
      dialogVisible: false,
      rules: rules,
      rulesCustom: rulesCustom,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
    ...mapGetters(['permissions']),
    permissionList() {
      return {
        viewBtn: this.vaildData(this.permissions.sys_login_view, false),
        conBtn: this.vaildData(this.permissions.sys_login_conn, false),
        loginBtn: this.vaildData(this.permissions.sys_login_in, false),
        submitBtn: this.vaildData(this.permissions.sys_login_add, false),
      }
    },
  },
  created() {
    this.getListSetting()
  },
  methods: {
    switchTab() {},
    getListSetting() {
      getListAuthMethodObj()
        .then(result => {
          if (!result.data.data) return
          result.data.data.forEach(val => {
            if (val.type === 'ldap') {
              this.ldapForm = val
              this.ldapForm.openFlag = this.ldapForm.openFlag === '1'
              this.ldapForm.bindPwd = null
              this.ldapFormCopy = deepClone(this.ldapForm)
            } else {
              this.selfDefinedForm = deepClone(val)
              this.selfDefinedForm.openFlag =
                this.selfDefinedForm.openFlag === '1'
              this.selfDefinedFormCopy = deepClone(this.selfDefinedForm)
            }
          })
        })
        .catch(() => {})
    },
    /**
     * 重置ldapForm
     */
    resetForm1() {
      this.$refs['ldapForm'].resetFields()
      this.ldapForm = deepClone(this.ldapFormCopy)
    },
    /**
     * 测试连接
     */
    testConn() {
      this.$refs['ldapForm'].validate(valid => {
        if (valid) {
          testLdapConnObj(this.ldapForm)
            .then(result => {
              if (result.data.code === 0) {
                return this.$notify.success(result.data.message)
              }
              this.$notify({
                title: '失败',
                message: result.data.message,
                type: 'warning',
                duration: 2000,
              })
            })
            .catch(() => {})
        }
      })
    },
    /**
     * 测试登录
     */
    beforeTestLogin() {
      this.dialogVisible = true
    },
    testLogin() {
      this.$refs['testLoginForm'].validate(valid => {
        if (valid) {
          let params = { ...this.testLoginForm }
          params.password = CryptoJS.AES.encrypt(
            params.password,
            CryptoJS.enc.Latin1.parse('paas for onlyone'),
            {
              iv: CryptoJS.enc.Latin1.parse('paas for onlyone'),
              mode: CryptoJS.mode.CBC,
              padding: CryptoJS.pad.ZeroPadding,
            },
          ).toString()
          testLdapLoginObj(params)
            .then(result => {
              if (result.data.code === 0) {
                this.dialogVisible = false
                this.$refs['testLoginForm'].resetFields()
                this.$notify.success(result.data.message)
              } else {
                this.$notify({
                  title: '失败',
                  message: result.data.message,
                  type: 'warning',
                  duration: 2000,
                })
              }
            })
            .catch(() => {})
        }
      })
    },
    handleClose() {
      this.dialogVisible = false
      this.$refs['testLoginForm'].resetFields()
    },
    /**
     * 提交ldapForm
     */
    submitForm1() {
      this.$refs['ldapForm'].validate(valid => {
        if (valid) {
          const params = deepClone(this.ldapForm)
          params.openFlag = params.openFlag === true ? '1' : '0'
          saveLdapObj(params)
            .then(result => {
              if (result.data.code === 0) {
                this.getListSetting()
                this.$notify.success('保存成功')
              } else {
                this.$notify({
                  title: '失败',
                  message: result.data.message,
                  type: 'warning',
                  duration: 2000,
                })
              }
            })
            .catch(() => {})
        }
      })
    },
    /**
     * 重置selfDefinedForm
     */
    resetForm2() {
      this.$refs['selfDefinedForm'].resetFields()
      this.selfDefinedForm = deepClone(this.selfDefinedFormCopy)
    },
    /**
     * 提交selfDefinedForm
     */
    submitForm2() {
      this.$refs['selfDefinedForm'].validate(valid => {
        if (valid) {
          const params = deepClone(this.selfDefinedForm)
          params.openFlag = params.openFlag === true ? '1' : '0'
          saveCustomObj(params)
            .then(result => {
              if (result.data.code === 0) {
                this.getListSetting()
                this.$notify.success('保存成功')
              } else {
                this.$notify.error(result.data.message)
              }
            })
            .catch(() => {})
        }
      })
    },
  },
}
</script>
<style lang="scss">
.ldap-container {
  background: #fff;
  width: calc(100% - 48px);
  padding: 20px;
  margin: 16px 24px;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  .el-tabs__content {
    padding-right: 120px;
    .el-form-item__content {
      line-height: 24px;
    }
  }
}
</style>
